import * as React from "react"
import { FunctionComponent, useState } from "react"
import Header from "../../components/header/Header"
import SimpleReactLightbox from "simple-react-lightbox"
import { graphql } from "gatsby"
import {
  Details,
  DetailsWrap,
  Info,
  Map,
  RestaurantWrap,
  Thumb,
  Subtitle,
  DeliLiepaja,
} from "./Restaurants.css"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import MainWrap from "../../components/main-wrap/MainWrap"
import MainTitle from "../../components/main-title/MainTitle"
import { HolderWrap } from "../../components/main-wrap/MainWrap.css"
import "./restaurant.css"
import { Lang, PageContext } from "../../constants"

export type Props = {
  data?: any
  pageContext: PageContext
}
export type Functions = {}
export type AllProps = Props & Functions
const customStyles = {
  overlay: {
    backgroundColor: "papayawhip",
  },
}

const Restaurants: FunctionComponent<AllProps> = ({ data, pageContext }) => {
  const allMarkdownRemark = data.restaurants
  const home = data.home.nodes[0].frontmatter
  const [activeLightBox, setActiveLightBox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [images, setImages] = useState([])
  const [imgTitle, setImgTitle] = useState("")

  return (
    <SimpleReactLightbox>
      <MainWrap>
        <Header lang={pageContext.lang} title={"Delisnack Liepāja Atrašanās vieta"} />
        <MainTitle>
          {pageContext.lang === Lang.LV ? "Atrašanās vieta" : "Location"}
        </MainTitle>

        <Subtitle>
          {`${
            pageContext.lang === Lang.LV
              ? home.deliveryEmail
              : home.deliveryEmail_en
          }`}
          &nbsp;
          <a href={`mailto:${home.email}`} target="_blank">
            {home.email}
          </a>
        </Subtitle>
        <HolderWrap>
          <RestaurantWrap>
            {allMarkdownRemark.nodes.map(({ frontmatter }, index) => (
              <li key={index}>
                <DetailsWrap>
                  <Info>
                    <h2>
                      {pageContext.lang === Lang.LV
                        ? frontmatter.address
                        : frontmatter.address_en}
                    </h2>
                  </Info>
                  <Details>
                    {frontmatter.workingHours &&
                      frontmatter.workingHours.length &&
                      frontmatter.workingHours.map(hours => (
                        <li
                          key={
                            pageContext.lang === Lang.LV
                              ? hours.days
                              : hours.days_en
                          }
                        >
                          <span>
                            {pageContext.lang === Lang.LV
                              ? hours.days
                              : hours.days_en}
                          </span>{" "}
                          {hours.time}
                        </li>
                      ))}
                    <li>
                      <a href={`tel:${frontmatter.phone}`}>
                        {frontmatter.phone}
                      </a>
                    </li>
                  </Details>
                  {Boolean(frontmatter?.gallery.length) && (
                    <>
                      <Thumb
                        src={frontmatter?.gallery[0].url}
                        onClick={() => {
                          setImgTitle(frontmatter.address)
                          setImages(frontmatter.gallery.map(item => item.url))
                          setActiveLightBox(true)
                        }}
                      />
                      {activeLightBox && (
                        <Lightbox
                          imageTitle={imgTitle}
                          enableZoom={false}
                          mainSrc={images[photoIndex]}
                          nextSrc={images[(photoIndex + 1) % images.length]}
                          prevSrc={
                            images[
                              (photoIndex + images.length - 1) % images.length
                            ]
                          }
                          onCloseRequest={() => {
                            setImgTitle("")
                            setPhotoIndex(0)
                            setActiveLightBox(false)
                          }}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + images.length - 1) % images.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex(
                              (photoIndex + images.length + 1) % images.length
                            )
                          }
                        />
                      )}
                    </>
                  )}
                </DetailsWrap>
                <Map
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.googleMapEmbed,
                  }}
                />
              </li>
            ))}
          </RestaurantWrap>
          <DeliLiepaja>
            Delisnack Riga visit <a href="https://www.delisnackriga.lv/" target="_blank">www.delisnackriga.lv</a>
          </DeliLiepaja>
        </HolderWrap>
      </MainWrap>
    </SimpleReactLightbox>
  )
}

export const query = graphql`
  {
    restaurants: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "restaurants" } }
        frontmatter: { googleMapEmbed: {}, phone: {}, title: {} }
      }
      sort: { fields: frontmatter___orderId, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          phone
          address
          address_en
          orderId
          workingHours {
            days
            days_en
            time
          }
          gallery {
            url
          }
          googleMapEmbed
        }
      }
    }
    home: allMarkdownRemark(
      filter: { fields: { collection: { eq: "home" } } }
    ) {
      nodes {
        frontmatter {
          email
          deliveryEmail
          deliveryEmail_en
        }
      }
    }
  }
`

export default Restaurants
