import styled from "styled-components"

export const HolderWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 75px;
  @media (max-width: 960px) {
    padding: 0 15px;
  }
`
