import * as React from "react"
import { FunctionComponent } from "react"
import { Title, Wrap } from "./MainTitle.css"
import StarIcon from "@material-ui/icons/Star"
export type Props = {
  responsiveTopNudge?:number
}
export type Functions = {}

export type AllProps = Props & Functions

export const MainTitle: FunctionComponent<AllProps> = ({ children,responsiveTopNudge }) => (
  <Wrap responsiveTopNudge={responsiveTopNudge}>
    <Title>
      <StarIcon style={{ color: "#F5A206", fontSize: "18px" }} />
      {children}
      <StarIcon style={{ color: "#F5A206", fontSize: "18px" }} />
    </Title>
  </Wrap>
)

export default MainTitle
