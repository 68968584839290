import styled from "styled-components"

export const Wrap = styled.div<{ responsiveTopNudge }>`
  display: flex;
  justify-content: center;
  padding: 60px 0;
  @media (max-width: 960px) {
    padding: ${({ responsiveTopNudge }) => responsiveTopNudge || 60}px 0 60px;
  }
`
export const Title = styled.h1`
  font-family: Font-Cond-Bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  font-size: 30px;
  padding: 0 40px;
  text-align: center;
  line-height: 32px;
  svg:first-child {
    left: 0;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
  }
  svg:last-child {
    right: 0;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
  }
`
