import styled from "styled-components"

export const Wrap = styled.div``
export const DetailsWrap = styled.div`
  width: 33%;
  @media (max-width: 960px) {
    width: 40%;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
`

export const Map = styled.div`
  flex: 1;
  margin-left: 60px;
  @media (max-width: 960px) {
    margin-left: 30px;
  }
  @media (max-width: 760px) {
    margin-left: 0;
    margin-top: 30px;
    height: 250px;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
`
export const RestaurantWrap = styled.ul`
  > li {
    border-bottom: 1px solid #fff;
    display: flex;
    padding-bottom: 40px;
    margin-bottom: 40px;
    @media (max-width: 760px) {
      display: block;
    }
  }
`

export const Info = styled.div`
  h2 {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: normal;
  }
`
export const Details = styled.ul`
  li {
    font-size: 17px;
    line-height: normal;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    span {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`

export const Thumb = styled.div<{ src: string }>`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url(${({ src }) => src});
  width: 350px;
  max-width: 100%;
  height: 220px;
  flex-shrink: 0;
  cursor: pointer;
  margin-top: 30px;
  @media (max-width: 760px) {
    width: 100%;
  }
`

export const Subtitle = styled.h2`
  margin: -30px 0 50px;
  font-size: 18px;
  text-align: center;
  padding: 0 5px;
  line-height: 22px;
  a {
    color: ${({ theme }) => theme.colors.orange};
  }
`
export const DeliLiepaja = styled.div`
  text-align: left;
  a {
    color: ${({ theme }) => theme.colors.orange};
  }
  padding-bottom: 40px;
`
